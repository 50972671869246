import React from 'react';
import cx from 'classnames';

import styles from './Footer.module.scss';
import { Link } from 'gatsby';

interface LinkType {
  text: string;
  href: string;
  target?: string;
}

interface IconLinkType {
  icon: {
    src: string;
    alt: string;
  };
  href: string;
}

interface FooterProps {
  links?: LinkType[];
  socialMediaLinks: IconLinkType[];
  mobileAppsLinks: IconLinkType[];
  subFooterContent: {
    legalLinks: LinkType[];
    copyrightText: string;
  };
  contactContent?: string;
}

function Footer({ links, socialMediaLinks, mobileAppsLinks, subFooterContent, contactContent }: FooterProps) {
  const renderLinks = links?.map(({ text, href, target }: LinkType, i: number) => (
    <React.Fragment key={i}>
      <li className={styles.footer_links_text}>
        <a href={href} target={target ? target : "_blank"} rel="noopener noreferrer">
          {text}
        </a>
      </li>
      <hr className={styles.divider}/>
    </React.Fragment>
  ));

  const renderSocialMediaLinks = socialMediaLinks.map(({ icon, href }: IconLinkType, i: number) => (
    <li className={styles.footer_links_icon} key={i}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <img src={icon.src} alt={icon.alt} />
      </a>
    </li>
  ));

  const renderMobileAppsLinks = mobileAppsLinks.map(({ icon, href }: IconLinkType, i: number) => (
    <li className={cx(styles.footer_links_icon, styles.footer_links_icon_mobile)} key={i}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <img src={icon.src} alt={icon.alt} />
      </a>
    </li>
  ));

  const renderLegalLinks = subFooterContent.legalLinks.map(
    ({ text, href }: LinkType, i: number) => (
      <li key={i}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </li>
    )
  );

  return (
    <footer className={styles.footer}>
      {contactContent && (
        <div className={styles.contact}>
          <p className={styles.contact_text} dangerouslySetInnerHTML={{ __html: contactContent }} />
          <Link
            className={styles.call_to_action}
            to='/'
          >
            <img src={require('assets/icons/arrow-left.svg')} />
            <span>Zurück</span>
          </Link>
        </div>
      )}
      <ul className={cx(styles.footer_links, styles.links)}>{renderLinks}</ul>
      <ul className={cx(styles.footer_links, styles.media_links)}>{renderSocialMediaLinks}</ul>
      <ul className={styles.footer_links}>{renderMobileAppsLinks}</ul>
      <div className={styles.footer_legal}>
        <ul>{renderLegalLinks}</ul>
        <p>{subFooterContent.copyrightText}</p>
      </div>
    </footer>
  );
}

export default Footer;
