import Config from '../config'

export default {
  facebook: 'https://www.facebook.com/hse.de',
  instagram: 'https://www.instagram.com/hse.de',
  pinterest: 'https://www.pinterest.de/hse_de',
  youtube: 'https://www.youtube.com/c/hseDE',
  linkedin: 'https://www.linkedin.com/company/hse/',
  xing: 'https://www.xing.com/pages/hsehomeshoppingeuropegmbh',
  ios_app: 'https://apps.apple.com/de/app/hse24/id1016812570?utm_campaign=AppStoreButton&utm_medium=landingpage&utm_source=webshop',
  android_app: 'https://play.google.com/store/apps/details?id=de.hse24&utm_source=webshop&utm_medium=landingpage&utm_campaign=AppStoreButton',
  impressum: 'https://www.hse.de/dpl/c/service/impressum',
  datenschutz: 'https://www.hse.de/dpl/c/service/datenschutz',
  karriere: 'https://jobs.hse.com/',
  onlineshop: 'https://www.hse.com/',
  innovate_hse:'https://innovate.hse.com/',
  investor: `${Config.baseUrl}/investors/`,
  kontakt: `${Config.baseUrl}/contact/`
};
