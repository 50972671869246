import { NavbarLink } from '../components/Hero/Navbar/Navbar';
import externalLinks from '../utils/externalLinks';

export const defaultNavbarLinks: NavbarLink[] = [
  {
    href: externalLinks.onlineshop,
    text: 'onlineshop',
    type: 'text',
  },
  {
    href: externalLinks.karriere,
    text: 'karriere',
    type: 'text',
  },
  {
    alt: 'HSE Logo',
    href: '/',
    src: require('assets/images/outline-logo.svg'),
    target: '_self',
    type: 'image',
  },
  {
    href: externalLinks.investor,
    text: 'Investor Relations',
    type: 'text',
    toggle: [
      {
        name: 'Fact Sheet',
        link: 'fact-sheet',
      },
      {
        name: 'Bond',
        link: 'bond',
      },
      {
        name: 'Publications',
        link: 'publications',
      },
      {
        name: 'Financial Calendar',
        link: 'financial-calendar',
      },
      {
        name: 'Rating',
        link: 'rating',
      },
    ],
  },
  {
    href: externalLinks.innovate_hse,
    text: 'HSE INNOVATES',
    type: 'text',
  },
];

export const defaultFooterLinks = [];

export const factSheet2023   = [
  { label: 'Revenue', value: '624m€' },
  { label: 'Adjusted EBITDA', value: '94m€' },
  { label: 'Adjusted EBITDA as reported*', value: '86m€' },
  {
    label: 'Products',
    value:
      '  The range comprises products from the fields of fashion, jewellery, beauty, wellness, household, and home & living.',
  },
  { label: 'TV-stations', value: '3 in DACH' },
  { label: 'Reach', value: '46m TV households in DACH' },
];

export const FinancialCalendar2022 = [
  {
    date: '30th May 2023',
    hour: '3 p.m. CET',
    labels: [
      'Publication of 3M 2023 Financial Reporting',
      'Publication of Q1 2023 Investor Presentation',
    ],
  },
  { date: '5th June 2023', hour: '11 a.m. CET', labels: ['Investor Call: Q1 2023 results'] },
  {
    date: '29th August 2023',
    hour: '3 p.m. CET',
    labels: [
      'Publication of 6M 2023 Financial Reporting',
      'Publication of Q2 2023 Investor Presentation',
    ],
  },
  { date: '7th September 2023', hour: '3 p.m. CET', labels: ['Investor Call: Q2 2023 results'] },
  {
    date: '28th November 2023',
    hour: '3 p.m. CET',
    labels: [
      'Publication of 9M 2023 Financial Reporting',
      'Publication of Q3 2023 Investor Presentation',
    ],
  },
  { date: '7th December 2023', hour: '3 p.m. CET', labels: ['Investor Call: Q3 2023 results'] },
  {
    date: '29th April 2024',
    hour: '3 p.m. CET',
    labels: [
      'Publication of FY 2023 Financial Reporting',
      'Publication of FY 2023 Investor Presentation',
    ],
  },
  { date: '7th May 2024', hour: '3 p.m. CET', labels: ['Investor Call: FY 2023 results'] },
];

export const FinancialCalendar2024 = [
  {
    date: '29th April 2024',
    hour: '3 p.m. CET',
    labels: [
      'Publication of FY 2023 Financial Reporting',
      'Publication of FY 2023 Investor Presentation',
    ],
  },
  { date: '7th May 2024', hour: '3 p.m. CET', labels: ['Investor Call: FY 2023 results'] },
  {
    date: '29th May 2024',
    hour: '3 p.m. CET',
    labels: [
      'Publication of 3M 2024 Financial Reporting',
      'Publication of Q1 2024 Investor Presentation',
    ],
  },
  { date: '6th June 2024', hour: '3 p.m. CET', labels: ['Investor Call: Q1 2024 results'] },
  {
    date: '29th August 2024',
    hour: '3 p.m. CET',
    labels: [
      'Publication of 6M 2024 Financial Reporting',
      'Publication of Q2 2024 Investor Presentation',
    ],
  },
  { date: '5th September 2024', hour: '3 p.m. CET', labels: ['Investor Call: Q2 2024 results'] },
  {
    date: '29th November 2024',
    hour: '3 p.m. CET',
    labels: [
      'Publication of 9M 2024 Financial Reporting',
      'Publication of Q3 2024 Investor Presentation',
    ],
  },
  { date: '9th December 2024', hour: '3 p.m. CET', labels: ['Investor Call: Q3 2024 results'] },
  {
    date: '30th April 2025',
    hour: '3 p.m. CET',
    labels: [
      'Publication of FY 2024 Financial Reporting',
      'Publication of FY 2024 Investor Presentation',
    ],
  },
  { date: '7th May 2025', hour: '3 p.m. CET', labels: ['Investor Call: FY 2024 results'] },
];

export const PUBS2024 = [
  {
    date: 'Q3 2024',
    pub: 'Announcement - Change in Management of HSE Finance S.à.r.l.',
    file: require('assets/documents/2024/20240917_Announcement_HSE_Finance_Sarl_Mgmt.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Q2 2024 Investor call (Dial-in information)',
    file: require('assets/documents/2024/6M_2024_Investor_call_Dial-in_information.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Q2 2024 Announcement Financial Results',
    file: require('assets/documents/2024/6M_2024_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Q2 2024 Investor Presentation',
    file: require('assets/documents/2024/6M_2024_Investor_Presentation_final_v2.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Q2 2024 Financial Reporting',
    file: require('assets/documents/2024/6M_2024_Financial_Reporting_HSE_Finance_Sarl.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Announcement - HSE exits its Russian activities',
    file: require('assets/documents/2024/20240618_Announcement_Russia.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Announcement - Change in the Management Board of Home Shopping Europe GmbH',
    file: require('assets/documents/2024/20240528_Announcement_Mgmt.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2024',
    pub: 'Announcement - Change in the Management Board of Home Shopping Europe GmbH',
    file: require('assets/documents/Q1_2024/240417_HSE_Change_in_management_Bond_signed.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q1 2024',
    pub: 'Q1 2024 Investor call (Dial-in information)',
    file: require('assets/documents/2024/Q1_2024_Investor_call_Dial-in_information.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2024',
    pub: 'Q1 2024 Announcement Financial Results',
    file: require('assets/documents/2024/Q1_2024_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2024',
    pub: 'Q1 2024 Investor Presentation',
    file: require('assets/documents/2024/Q1_2024_Investor_Presentation_v2.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2024',
    pub: '3M 2024 Financial Reporting',
    file: require('assets/documents/2024/3M_2024_Financial_Reporting.pdf'),
    divider: true,
  },
];

export const PUBS2023 = [
  {
    date: 'FY 2023',
    pub: 'FY 2023 Investor call (Dial-in information)',
    file: require('assets/documents/FY_2023/FY_2023_Investor_call_Dial-in_information.pdf'),
    divider: true,
  },
  {
    date: 'FY 2023',
    pub: 'FY 2023 Announcement Financial Results',
    file: require('assets/documents/FY_2023/FY_2023_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'FY 2023',
    pub: 'FY 2023 Investor Presentation',
    file: require('assets/documents/FY_2023/FY_2023_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'FY 2023',
    pub: 'FY 2023 Financial Reporting (Audit report)',
    file: require('assets/documents/FY_2023/FY_2023_Financial_Reporting_Audit_Report.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q4 2023',
    pub: 'Announcement - Change in the Management Board of Home Shopping Europe GmbH',
    file: require('assets/documents/Q4_2023/20231219_Change_in_Management_HSE.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q3 2023',
    pub: 'Q3 2023 Investor call (Dial-in information)',
    file: require('assets/documents/Q3_2023/Q3_2023_Investor_call_Dial-in-information.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2023',
    pub: 'Q3 2023 Announcement Financial Results',
    file: require('assets/documents/Q3_2023/Q3_2023_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2023',
    pub: 'Q3 2023 Investor Presentation',
    file: require('assets/documents/Q3_2023/Q3_2023_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2023',
    pub: '9M 2023 Financial Reporting',
    file: require('assets/documents/Q3_2023/9M_2023_Financial_Reporting.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2023',
    pub: 'Announcement - New Board Member',
    file: require('assets/documents/Q3_2023/230921_HSE_Personalie_AndreaDeSantis_ENG_final.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q2 2023',
    pub: 'Q2 2023 Investor Call (Dial-in information)',
    file: require('assets/documents/Q2_2023/Q2-2023_Investor_call_Dial-in_information.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2023',
    pub: 'Q2 2023 Announcement Financial Results',
    file: require('assets/documents/Q2_2023/Q2_2023_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2023',
    pub: 'Q2 2023 Investor Presentation',
    file: require('assets/documents/Q2_2023/Q2_2023_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2023',
    pub: '6M 2023 Financial Reporting',
    file: require('assets/documents/Q2_2023/6M_2023_Financial_Reporting.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2023',
    pub: '2022 ESG Report',
    file: require('assets/documents/Q2_2023/2022_ESG_REPORT_eng.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q1 2023',
    pub: 'Q1 2023 Investor Call (Dial-in information)',
    file: require('assets/documents/Q1_2023/Q1_2023_Investor_call_Dial-in_information.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2023',
    pub: 'Q1 2023 Announcement Financial Results',
    file: require('assets/documents/Q1_2023/Q1_2023_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2023',
    pub: 'Q1 2023 Investor Presentation',
    file: require('assets/documents/Q1_2023/Q1_2023_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2023',
    pub: '3M 2023 Financial Reporting',
    file: require('assets/documents/Q1_2023/3M_2023_Financial_Reporting.pdf'),
    divider: true,
  },
];

export const PUBS2022 = [
  {
    date: 'FY 2022',
    pub: 'FY 2022 Investor call (Dial-in information)',
    file: require('assets/documents/FY_2022/FY_2022_Investor_call_Dial-in_information.pdf'),
    divider: true,
  },
  {
    date: 'FY 2022',
    pub: 'FY 2022 Announcement Financial Results',
    file: require('assets/documents/FY_2022/FY_2022_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'FY 2022',
    pub: 'FY 2022 Investor Presentation',
    file: require('assets/documents/FY_2022/FY_2022_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'FY 2022',
    pub: 'FY 2022 Financial Reporting (Audit report)',
    file: require('assets/documents/FY_2022/FY_2022_Financial_reporting_Audit_report.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q3 2022',
    pub: 'Q3 2022 Investor call (Dial-in information)',
    file: require('assets/documents/Q3_2022/Q3_2022_Investor_call_Dial-in-information.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2022',
    pub: 'Q3 2022 Announcement Financial Results',
    file: require('assets/documents/Q3_2022/Q3_2022_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2022',
    pub: 'Q3 2022 Investor Presentation',
    file: require('assets/documents/Q3_2022/Q3_2022_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'Q3 2022 ',
    pub: '9M 2022 Financial Reporting',
    file: require('assets/documents/Q3_2022/9M-2022-Financial-Reporting.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q2 2022',
    pub: 'Q2 2022 Investor call (Dial-in information)',
    file: require('assets/documents/Q2_2022/Q2_2022_Investor_call_Dial-in-information.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2022',
    pub: 'Q2 2022 Announcement Financial Results',
    file: require('assets/documents/Q2_2022/Q2_2022_Announcement_Financial_Results.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2022',
    pub: 'Q2 2022 Investor Presentation',
    file: require('assets/documents/Q2_2022/Q2_2022_Investor-Presentation.pdf'),
    divider: true,
  },
  {
    date: 'Q2 2022 ',
    pub: '6M 2022 Financial Reporting',
    file: require('assets/documents/Q2_2022/6M_2022_Financial_Reporting.pdf'),
    divider: true,
    spacing: true,
  },
  {
    date: 'Q1 2022',
    pub: 'Q1 2022 Investor Call (Dial-in information)',
    file: require('assets/documents/Q12022/Q1_2022_Investor_call.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2022',
    pub: 'Q1 2022 Announcement Financial Results',
    file: require('assets/documents/Q12022/Q1_2022_Announcement_Financial_Results.pdf'),
    divider: true,
  },

  {
    date: 'Q1 2022',
    pub: 'Q1 2022 Investor Presentation',
    file: require('assets/documents/Q12022/Q1_2022_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'Q1 2022',
    pub: '3M 2022 Financial Reporting',
    file: require('assets/documents/Q12022/Q1_2022_3M _2022_Financial_Reporting.pdf'),
    divider: true,
  },
];
