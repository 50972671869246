import { Location } from '@reach/router';
import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Config from '../../../config';
import useScroll from '../../../hooks/useScroll';
import styles from './Navbar.module.scss';

interface NavbarLinkBase {
  href: string;
  target?: string;
}

export interface NavbarTextLink extends NavbarLinkBase {
  type: 'text';
  text: string;
  toggle?: ToggleLink[];
}

export interface NavbarImageLink extends NavbarLinkBase {
  type: 'image';
  src: string;
  alt: string;
  toggle?: ToggleLink[];
}

export interface ToggleLink {
  name: string;
  link: string;
}

export type NavbarLink = NavbarTextLink | NavbarImageLink;

interface Props {
  links: NavbarLink[];
  sticky?: boolean;
}

function Navbar(props: Props) {
  const isBrowser = typeof window !== 'undefined';
  const { sticky = true } = props;
  const { direction, y } = useScroll(0);
  const navRef = useRef<HTMLElement>(null);

  const [isSticky, setIsSticky] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isOutsideOfHeroSection, setIsOutsideOfHeroSection] = useState(false);
  const [isMenuButtonClicked, setIsMenuButtonClicked] = useState<boolean>(false);
  const [isSubMenuToggled, setIsSubMenuToggled] = useState<boolean>(
    isBrowser && window.innerWidth <= 768 ? false : true
  );

  useLayoutEffect(() => {
    const nav = navRef.current;

    if (nav?.parentElement) {
      setIsSticky(y > nav.offsetTop + nav.parentElement.offsetTop);
      setIsHidden(direction === 'down' && y > nav.offsetTop + nav.parentElement.offsetTop + 150);

      setIsOutsideOfHeroSection(y > nav.parentElement.clientHeight + nav.parentElement.offsetTop);
    }
  }, [y]);

  const goToModule = (id: string, link: string, href: string) => {
    const section = document.getElementById(id);
    window.scrollTo({
      top: section?.offsetTop,
      behavior: 'smooth',
    });

    window.history.replaceState({}, link, `${link}#${href}`);
  };

  const handleMenuButtonClick = () => {
    setIsMenuButtonClicked(true);
  };

  const handleCloseButtonClick = () => {
    setIsMenuButtonClicked(false);
    setIsSubMenuToggled(false);
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuToggled(!isSubMenuToggled);
  };

  return (
    <Location>
      {({ location }) => (
        <nav
          ref={navRef}
          className={classNames(styles.navbar, {
            [styles.navbar_sticky]: isSticky && sticky,
            [styles.navbar_hidden]: isHidden,
            [styles.navbar_outside_hero_section]: isOutsideOfHeroSection,
            [styles.navbar_sticky_by_default]: !sticky,
          })}
        >
          <img
            className={styles.mobileLogo}
            src={require('assets/images/outline-logo.svg')}
            alt="HSE Logo"
          />
          <div className={styles.navigation_menu}>
            <div className={styles.navigation_menu_block} onClick={handleMenuButtonClick}>
              <img src={require('assets/icons/hamburger-menu.svg')} alt="Hamburger Menu" />
              <span>Menü</span>
            </div>
          </div>
          {isMenuButtonClicked && (
            <>
              <img
                className={styles.closeIcon}
                onClick={handleCloseButtonClick}
                src={require('assets/icons/close-icon.svg')}
                alt="Close Icon"
              />
              <div onClick={handleCloseButtonClick} className={styles.outsideNavBarList}></div>
            </>
          )}
          <ul className={styles.NavBarList} style={{ left: isMenuButtonClicked ? 0 : '-100%' }}>
            {props.links.map((link: NavbarLink, i) => (
              <li key={i}>
                <a
                  href={link.toggle ? undefined : link.href}
                  target={link.target ? link.target : '_blank'}
                  rel="noopener noreferrer"
                  data-testid={link.type}
                >
                  {link.type === 'text' ? (
                    <>
                      {link.toggle && isMenuButtonClicked ? (
                        <>
                          <span onClick={handleSubMenuToggle}>{link.text}</span>
                          <div className={styles.arrowIcon} onClick={handleSubMenuToggle}>
                            <img src={require('assets/icons/arrow-right.svg')} alt="Arrow Right" />
                          </div>
                        </>
                      ) : (
                        <span>{link.text}</span>
                      )}
                    </>
                  ) : (
                    <img
                      className={classNames(styles.logo, {
                        [styles.logo_active]: isSticky && isOutsideOfHeroSection && sticky,
                      })}
                      src={link.src}
                      alt={link.alt}
                    />
                  )}
                </a>

                {link.toggle && isSubMenuToggled && (
                  <div className={styles.navBarSubMenu}>
                    <ul className={styles.toggleList}>
                      <div className={styles.navBarsubMenuItem}>
                        {link.toggle.map((toggle: ToggleLink, i) => {
                          return location.pathname === link.href.replace(Config.baseUrl, '') ? (
                            <li
                              onClick={() =>
                                goToModule(toggle.link, location.pathname, toggle.link)
                              }
                              key={i}
                            >
                              {' '}
                              <a> {toggle.name} </a>{' '}
                            </li>
                          ) : (
                            <li key={i}>
                              {' '}
                              <a href={`${link.href}#${toggle.link}`}> {toggle.name} </a>{' '}
                            </li>
                          );
                        })}
                      </div>
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )}
    </Location>
  );
}

export default Navbar;
